const importedPersonality = [
  {
    name: 'Conductor',
    description: 'D - People who tend to be direct, decisive, and seek results.'
  },
  {
    name: 'Persuader',
    description: 'D/I - People who tend to convince others by appealing to reason, understanding, or emotion.'
  },
  {
    name: 'Promoter',
    description: 'I - People who tend to verbalize many thoughts to influence outcomes.'
  },
  {
    name: 'Relater',
    description: 'I/S - People who tend to take time, think positively, and are focused on interpersonal relationships.'
  },
  {
    name: 'Supporter',
    description: 'S - People who tend to be champions of sound ideas, working steadily and diligently to ensure a project is fully realized.'
  },
  {
    name: 'Coordinator',
    description: 'S/C - People who tend to be fact-oriented and adhere to proven methods to complete projects and tasks.'
  },
  {
    name: 'Analyser',
    description: 'C - People who tend to seek out accuracy in all activities and ensure the highest quality possible by gathering precise data.'
  },
  {
    name: 'Implementor',
    description: 'C/D - People who tend to assess, leverage facts and figures, and advance toward a solution.'
  }
]
export { importedPersonality }
