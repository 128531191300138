<template>
  <div>
    <h4
      class="resume-titles"
      v-text="$t('Pages.Profile.experiences_assignments')"
    />
    <v-row
      v-if="experiences.length > 0"
      class="resume-cards resume-experience"
    >
      <v-col
        v-for="(experience, index) in experiences"
        :id="'experience-' + index"
        :key="'experience-' + index"
        cols="12"
        sm="6"
      >
        <div
          is="ProfileCard"
          :title="experience.company"
          :subtitle="experience.jobTitle"
          :second-line-subtitle="getExperienceDates(experience)"
          :description="experience.description"
        >
          <div slot="tooltip">
            <span
              v-if="experience.monthsWorked !== null && experience.monthsWorked !== undefined"
            >
              <v-tooltip
                color="light-blue darken-4"
                right
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-icon>schedule</v-icon>
                    <span
                      v-if="experience.monthsWorked >= 12"
                      :id="'experience-year-' + index"
                    >
                      {{ formatDate.formatYear(experience.monthsWorked) }}
                    </span>
                    <span :id="'experience-months-' + index">
                      {{ formatDate.formatRemainingMonths(experience.monthsWorked) }}
                    </span>
                  </span>
                </template>
                <span v-text="$t('Pages.Profile.duration')" />
              </v-tooltip>
            </span>
            <div
              v-if="experience && experience.experiences && experience.experiences[0].description"
              class="mt-2"
            >
              {{ experience.experiences[0].description }}
            </div>
          </div>
          <v-tooltip
            slot="action-buttons"
            color="light-blue darken-4"
            bottom
          >
            <template #activator="{ on }">
              <v-icon
                v-if="hasApprovedReferences(experience)"
                :id="'open-experience-' + index"
                class="ma-2"
                v-on="on"
              >
                mdi-approval
              </v-icon>
            </template>
            <span>{{ $t('Pages.Profile.verified_reference') }}</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="resume-cards resume-experience"
    >
      <v-col class="text-center py-4">
        <EmptySectionText
          icon="work"
          :title="$t('Pages.Profile.no_experiences_assignments')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
import { formatDate } from '@/config/formatDate.js'
export default {
  name: 'PublicProfileExperiences',
  components: {
    EmptySectionText,
    ProfileCard
  },
  props: {
    experiences: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      formatDate
    }
  },
  methods: {
    hasApprovedReferences (experience) {
      // if any of the references have been approved, return true
      if (experience.references) {
        for (let i = 0; i < experience.references.length; i++) {
          if (experience.references[i].approved) {
            return true
          }
        }
      } else {
        return false
      }
    },
    getExperienceTitles (jobTitles) {
      if (jobTitles) {
        let titlesString = ''
        for (let i = 0; i < jobTitles.length; i++) {
          if (i === jobTitles.length - 1) {
            titlesString += jobTitles[i].jobTitle
          } else {
            titlesString += jobTitles[i].jobTitle + ', '
          }
        }
        return titlesString
      } else {
        return null
      }
    },
    getExperienceDates (experience) {
      let dateString = ''
      if (experience.startDate) {
        dateString += this.formatTimestamp(experience.startDate).substring(0, 4)
      }
      if (experience.endDate) {
        dateString += ' - ' + this.formatTimestamp(experience.endDate).substring(0, 4)
      } else if (experience.ongoing) {
        dateString += ' - ' + this.$t('Pages.Profile.present')
      }
      return dateString
    },
    formatTimestamp (timestamp) {
      const date = new Date(timestamp)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }
}
</script>
