const colors = {
  Instinctive: 'dark-red-chip',
  Intellectual: 'dark-red-chip',
  Selfless: 'orange darken-3',
  Resourceful: 'orange darken-3',
  Objective: 'blue darken-4',
  Harmonious: 'blue darken-4',
  Intentional: 'teal darken-2',
  Altruistic: 'teal darken-2',
  Commanding: 'grey darken-4',
  Collaborative: 'grey darken-4',
  Receptive: 'grey darken-1',
  Structured: 'grey darken-1'
}

export { colors }
