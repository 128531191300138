const importedDrivingForces = [
  {
    name: 'Instinctive',
    description:
      'People who are driven by utilizing past experiences, intuition and seeking specific knowledge when necessary.'
  },
  {
    name: 'Intellectual',
    description:
      'People who are driven by opportunities to learn, acquire knowledge and the discovery of truth.'
  },
  {
    name: 'Selfless',
    description:
      'People who are driven by completing tasks for the sake of completion, with little expectation of personal return.'
  },
  {
    name: 'Resourceful',
    description:
      'People who are driven by practical results, maximizing both efficiency and returns for their investments of time, talent, energy and resources.'
  },
  {
    name: 'Objective',
    description:
      'People who are driven by the functionality and objectivity of their surroundings.'
  },
  {
    name: 'Harmonious',
    description:
      'People who are driven by the experience, subjective viewpoints and balance in their surroundings.'
  },
  {
    name: 'Intentional',
    description:
      'People who are driven to assist others for a specific purpose, not just for the sake of being helpful or supportive.'
  },
  {
    name: 'Altruistic',
    description:
      'People who are driven to assist others for the satisfaction of being helpful or supportive.'
  },
  {
    name: 'Collaborative',
    description:
      'People who are driven by being in a supporting role and contributing with little need for individual recognition.'
  },
  {
    name: 'Commanding',
    description:
      'People who are driven by status, recognition and control over personal freedom.'
  },
  {
    name: 'Receptive',
    description:
      'People who are driven by new ideas, methods and opportunities that fall outside a defined system for living.'
  },
  {
    name: 'Structured',
    description:
      'People who are driven by traditional approaches, proven methods and a defined system for living.'
  }
]
export { importedDrivingForces }
