<template>
  <div>
    <h4
      class="resume-titles mb-3"
      v-text="$t('Pages.Profile.short_description')"
    />
    <div
      v-if="description"
      class="top-card mb-3"
    >
      <v-card
        class="pa-3 rounded-xl"
        color="neutral"
        v-text="description"
      />
    </div>
    <div v-else>
      <EmptySectionText
        icon="description"
        :title="$t('Pages.Profile.no_description')"
      />
    </div>
  </div>
</template>

<script>
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
export default {
  name: 'PublicProfileDescription',
  components: {
    EmptySectionText
  },
  props: {
    description: {
      type: String,
      default: ''
    }
  }
}
</script>
