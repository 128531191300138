<template>
  <v-alert
    id="profile-hidden-banner"
    :value="publicProfile.hidden === true"
    class="alert-clipped default-alert"
    color="info"
  >
    <div
      class="wides"
      style="margin: auto;"
    >
      <v-icon
        class="mr-2"
        style="color: rgba(0,0,0,.3);"
      >
        info
      </v-icon> {{ $t('Pages.Profile.public_profile_hidden') }}
    </div>
  </v-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PublicProfileVisibilityAlert',
  computed: {
    ...mapState([
      'isAdmin',
      'publicProfile'
    ])
  }
}
</script>
