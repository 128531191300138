<template>
  <v-card
    class="rounded-xl"
    color="neutral"
  >
    <v-container fluid>
      <v-row
        justify="center"
        class="profile-info"
      >
        <v-col
          cols="12"
          lg="10"
          class="wides"
        >
          <v-container fluid>
            <v-row
              justify="center"
            >
              <v-col cols="12">
                <div class="profile-pic-container">
                  <div class="profile-content">
                    <img
                      v-if="profile.picture && profile.picture.url"
                      :src="profile.picture.url"
                      style="border-radius: 50%;"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      max-width="160"
                      max-height="160"
                    >
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="text-center pt-0"
              >
                <div
                  id="public-profile-fullname"
                  class="text-center text-h5 mb-3"
                >
                  {{ profile.firstName }} {{ profile.lastName }}
                </div>
                <!--
                <AvailabilityChip
                  :chip="profile.availability"
                  :is-large="true"
                  class="mb-3"
                />
                -->
                <div class="d-flex align-center justify-center">
                  <div
                    v-if="$route.params.id !== user.userId"
                  >
                    <v-btn
                      id="contact-user"
                      class="mb-3"
                      color="secondary neutral--text"
                      rounded
                      @click="$emit('connect-click')"
                      v-text="!isPremium ? $t('Pages.Profile.buy_subscription') : $t('Pages.Profile.contact_consultant')"
                    />
                    <v-btn
                      v-if="user.subscriptions && user.subscriptions.createContract"
                      id="send-contract"
                      class="mb-3"
                      color="secondary neutral--text"
                      rounded
                      @click="$emit('contract-click')"
                      v-text="$t('Pages.Profile.send_contract')"
                    />
                  </div>
                  <div v-else>
                    <v-btn
                      id="edit-profile-link"
                      :to="{ name: 'edit-profile' }"
                      color="primary"
                      class="ma-2"
                      rounded
                      v-text="$t('Pages.Profile.edit_profile')"
                    />
                  </div>
                </div>
                <div
                  v-if="profile.locations.length"
                  class="text-center mb-3"
                >
                  <v-icon
                    small
                    class="icon-link"
                  >
                    place
                  </v-icon>
                  <span
                    v-for="(loc, index) in profile.locations"
                    :key="index"
                  ><span v-if="index !== 0">, </span>{{ loc }}</span>
                </div>
                <div
                  v-if="profile.drivingPermits"
                  class="text-center"
                >
                  <span v-if="profile.drivingPermits.length">
                    <v-icon
                      small
                      class="icon-link-public"
                    >
                      directions_car
                    </v-icon>
                    {{ profile.drivingPermits.length ? $t('Pages.Profile.drivers_license') +':' : $t('Pages.Profile.drivers_licenses') +':' }}
                    <span
                      v-for="(permit, index) in profile.drivingPermits"
                      :key="index"
                    >
                      <span :id="'license-' + index">{{ permit }}</span>
                      <span v-if="index !== profile.drivingPermits.length - 1">,&nbsp;</span>
                    </span>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      id="resume-dialog"
      v-model="resumeCarouselDialog"
      class="resume-dialog"
      style="max-width: 1200px !important; width: 100%;"
      width="100%"
      max-width="1200px"
    >
      <v-carousel
        v-if="profile.resumes && profile.resumes.length && profile.resumes[0].pages"
        id="resume-carousel"
        :cycle="false"
        height="auto"
        hide-delimiters
      >
        <v-carousel-item
          v-for="(page, i) in profile.resumes[0].pages"
          :key="i"
          :src="page.s3url"
        />
      </v-carousel>
    </v-dialog>
    <v-dialog
      v-model="ratingDialog"
    >
      <CustomDialog
        :title="profile.firstName + ' ' + profile.lastName + 's Ratings and reviews'"
        :close-button="{
          id: 'close-rating-dialog',
          text: 'Close'
        }"
        @close-event="ratingDialog = false"
      >
        <div slot="custom">
          <div class="text-h5">
            {{ profile.firstName }} has received the following rating by references and employers
          </div>
          <p>
            <v-tooltip
              bottom
              color="light-blue darken-4"
            >
              <template #activator="{ on }">
                <span
                  class="normal-cursor"
                  v-on="on"
                  @click="ratingDialog = true"
                >
                  <v-icon>sentiment_very_satisfied</v-icon>
                  <span class="public-profile-rating-amount">({{ checkRating(3) }})</span>
                </span>
              </template>
              <span>Good rating</span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="light-blue darken-4"
            >
              <template #activator="{ on }">
                <span
                  class="normal-cursor"
                  v-on="on"
                  @click="ratingDialog = true"
                >
                  <v-icon color="accent">star</v-icon>
                  <span class="public-profile-rating-amount">({{ checkRating(4) }})</span>
                </span>
              </template>
              <span>Highest rating</span>
            </v-tooltip>
          </p>
          <div v-if="profile.reviews && profile.reviews.length">
            <div class="text-h5">
              {{ profile.firstName }}s reviews:
            </div>
            <div class="public-profile-scrollable-reviews">
              <div
                v-for="review in profile.reviews"
                :key="review.id"
              >
                <p v-if="review.review && review.review.length">
                  <i>"{{ review.review }}" - {{ formatDate.basic(review.createdAt) }}</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </CustomDialog>
    </v-dialog>
    <v-dialog
      v-model="userLinksDialog"
      width="400"
    >
      <CustomDialog
        :title="profile.firstName + ' ' + profile.lastName + 's Links'"
        :close-button="{
          id: 'close-links-dialog',
          text: 'Close'
        }"
        @close-event="userLinksDialog = false"
      >
        <v-card
          slot="custom"
          color="neutral"
          class="rounded-xl"
        >
          <v-card-text>
            <div
              v-for="(link, index) in profile.links"
              :key="index"
              class="mb-2"
            >
              <v-card
                class="link-card rounded-xl"
                :href="link.value"
                target="_blank"
                rel="noreferrer"
                color="neutral"
              >
                <v-row
                  class="py-2 px-3"
                  align="center"
                  justify="center"
                >
                  <v-icon class="mr-3">
                    link
                  </v-icon>
                  <v-col class="mobile-overflow-x">
                    <div class="text-h5 text-left ma-0">
                      {{ link.title }}
                    </div>
                    <span class="text-left ma-0">
                      {{ link.value }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </CustomDialog>
    </v-dialog>
  </v-card>
</template>

<script>
import CustomDialog from '@/common/CustomDialog.vue'

import { formatDate } from '@/config/formatDate.js'
import { mapState } from 'vuex'
// import AvailabilityChip from '@/common/AvailabilityChip.vue'
export default {
  components: {
    // AvailabilityChip,
    CustomDialog
  },
  props: {
    profile: {
      type: Object,
      default: function () {
      }
    }
  },
  data () {
    return {
      formatDate,
      resumeCarouselDialog: false,
      userLinksDialog: false,
      ratingDialog: false
    }
  },
  computed: {
    ...mapState([
      'user',
      'isPremium'
    ])
  },
  methods: {
    checkRating (number) {
      let amount = 0
      for (const i in this.profile.reviews) {
        if (this.profile.reviews[i].rating === number) amount += 1
      }
      return amount
    }
  }
}
</script>
