<template>
  <div v-if="pieSize === 'medium'">
    <slot name="background" />
    <div
      id="pieSliceRedBlue"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Implementor'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
    <div
      id="pieSliceBlue"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Analyser'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
    <div
      id="pieSliceGreen"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Supporter'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
    <div
      id="pieSliceRed"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Conductor'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
    <div
      id="pieSliceYellow"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Promoter'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
    <div
      id="pieSliceyellowRed"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Persuader'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
    <div
      id="pieSliceGreenBlue"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Coordinator'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
    <div
      id="pieSliceGreenYellow"
      class="hold search-hold"
    >
      <v-icon
        v-show="personality === 'Relater'"
        class="dot search-dot"
      >
        lens
      </v-icon>
      <div class="pie search-pie" />
    </div>
  </div>
  <div v-else-if="pieSize === 'small'">
    <slot name="background" />
    <div
      id="pieSliceRedBlue"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Implementor'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
    <div
      id="pieSliceBlue"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Analyser'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
    <div
      id="pieSliceGreen"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Supporter'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
    <div
      id="pieSliceRed"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Conductor'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
    <div
      id="pieSliceYellow"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Promoter'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
    <div
      id="pieSliceyellowRed"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Persuader'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
    <div
      id="pieSliceGreenBlue"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Coordinator'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
    <div
      id="pieSliceGreenYellow"
      class="hold small-hold"
    >
      <v-icon
        v-if="personality === 'Relater'"
        class="dot small-dot"
      >
        lens
      </v-icon>
      <div class="pie small-pie" />
    </div>
  </div>
  <div v-else>
    <slot name="background" />
    <div
      id="pieSliceRedBlue"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Implementor'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
    <div
      id="pieSliceBlue"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Analyser'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
    <div
      id="pieSliceGreen"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Supporter'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
    <div
      id="pieSliceRed"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Conductor'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
    <div
      id="pieSliceYellow"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Promoter'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
    <div
      id="pieSliceyellowRed"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Persuader'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
    <div
      id="pieSliceGreenBlue"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Coordinator'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
    <div
      id="pieSliceGreenYellow"
      class="hold"
    >
      <v-icon
        v-if="personality === 'Relater'"
        class="dot"
      >
        lens
      </v-icon>
      <div class="pie" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscPie',
  props: {
    personality: {
      type: String,
      default: ''
    },
    pieSize: {
      type: String,
      default: ''
    }
  }
}
</script>
