const personalities = {
  Implementor: 'Implementors are hard-working, rational, disciplined and independent people. They do not like to make mistakes, and push themselves to always improve. An Implementor often states the rules, standards and the ethics in a team or company.',
  Analyser: 'Analyzers are logic, structured, methodic and quality minded. They are focused on facts, honor and responsibility. They can go deep into details and have no problems on working on their own.',
  Supporter: 'Supporters value inner harmony. They are stable, self-controlled calm individuals who very much like to listen to other people’s opinion and believe that consensus is the highest of all conditions.',
  Conductor: 'A Conductor is a person with a very strong will. They have a strong drive and a lot of energy to reach a goal, even if it looks impossible to reach. Decisions are made quickly and with no difficulties.',
  Promoter: 'Promoters are outspoken, optimistic, sympathetic and extremely person oriented. It is very important to have many connections to other people.',
  Persuader: 'Persuaders are innovative, creative, enthusiastic and flexible. They are extremely receptive to other people’s ideas and have an intuitive feeling when it comes to seeing opportunities in ideas.',
  Coordinator: 'Coordinators are often systematic, thorough, organised and questioning. They are master organizers capable of transforming unordered data into comprehensible and organized information.',
  Relater: 'Relaters are open, sympathetic, understanding and loving people who like to be popular. They value themselves from how much other people likes them.'
}

export { personalities }
