<template>
  <div v-if="publicProfile.ipu.disc && !publicProfile.ipu.hidden">
    <v-tabs
      v-if="publicProfile.ipu.disc[0] && !publicProfile.ipu.hidden"
      v-model="ipuTabs"
      show-arrows
      centered
      background-color="neutral"
      color="secondary"
      grow
    >
      <v-tab id="view-wheel-personality-traits">
        <v-icon>poll</v-icon>
      </v-tab>
      <v-tab id="view-wheel-personality-behavior">
        <v-icon>pie_chart</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-show="publicProfile.ipu.disc[0]"
      v-model="ipuTabs"
      class="ipu-tabs neutral"
    >
      <v-tab-item class="pb-5 pt-4">
        <v-card
          flat
          color="neutral"
          class="rounded-xl"
        >
          <h3
            class="text-center pt-2 mb-4 pb-4"
            v-text="$t('Disc.personality_traits')"
          />
          <v-row
            v-if="publicProfile.ipu.disc[0]"
            class="ipu-layout my-1"
          >
            <v-tooltip
              bottom
              color="light-blue darken-4"
            >
              <template #activator="{ on }">
                <div
                  id="redbar"
                  class="ipu-bar"
                  :style="{ height: ipuData.d * 2 + 'px' }"
                  v-on="on"
                >
                  <span class="bar-value top">
                    <strong>{{ publicProfile.ipu.disc[0].d }}</strong>
                  </span>
                  <span class="bar-value bottom">
                    <strong>D</strong>
                  </span>
                </div>
              </template>
              <span>{{ publicProfile.ipu.disc[0].d }}% Dominance</span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="light-blue darken-4"
            >
              <template #activator="{ on }">
                <div
                  id="yellowbar"
                  class="ipu-bar"
                  :style="{ height: ipuData.i * 2 + 'px' }"
                  v-on="on"
                >
                  <span class="bar-value top">
                    <strong>{{ publicProfile.ipu.disc[0].i }}</strong>
                  </span>
                  <span class="bar-value bottom">
                    <strong>I</strong>
                  </span>
                </div>
              </template>
              <span>{{ publicProfile.ipu.disc[0].i }}% Influence</span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="light-blue darken-4"
            >
              <template #activator="{ on }">
                <div
                  id="greenbar"
                  class="ipu-bar"
                  :style="{ height: ipuData.s * 2 + 'px' }"
                  v-on="on"
                >
                  <span class="bar-value top">
                    <strong>{{ publicProfile.ipu.disc[0].s }}</strong>
                  </span>
                  <span class="bar-value bottom">
                    <strong>S</strong>
                  </span>
                </div>
              </template>
              <span>{{ publicProfile.ipu.disc[0].s }}% Steadiness</span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="light-blue darken-4"
            >
              <template #activator="{ on }">
                <div
                  id="bluebar"
                  class="ipu-bar"
                  :style="{ height: ipuData.c * 2 + 'px' }"
                  v-on="on"
                >
                  <span class="bar-value top">
                    <strong>{{ publicProfile.ipu.disc[0].c }}</strong>
                  </span>
                  <span class="bar-value bottom">
                    <strong>C</strong>
                  </span>
                </div>
              </template>
              <span>{{ publicProfile.ipu.disc[0].c }}% Conscientiousness</span>
            </v-tooltip>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item class="pb-2 pt-4">
        <v-card
          flat
          color="neutral"
          class="rounded-xl"
        >
          <div
            class="text-center text-h5 mb-4"
            v-text="$t('Disc.behavior')"
          >
            Behavior
          </div>
          <div class="ipu-wheel behavioral-profile">
            <v-tooltip
              max-width="200"
              top
              color="light-blue darken-4"
            >
              <template #activator="{ on }">
                <div
                  is="DiscPie"
                  id="piediv"
                  :personality="publicProfile.ipu.wheel && publicProfile.ipu.wheel.length ? publicProfile.ipu.wheel[0].personality : ''"
                  :smaller-pie="false"
                  class="pieContainer"
                  v-on="on"
                >
                  <div
                    slot="background"
                    class="pieBackground"
                  />
                </div>
              </template>
              <span>
                <div class="types">
                  <strong
                    v-if="isPersonality && publicProfile.ipu.wheel.length > 0 && publicProfile.ipu.wheel[0]"
                  >
                    {{ publicProfile.ipu.wheel[0].personality }}
                  </strong>
                  <strong v-else>
                    No position
                  </strong>
                </div>
              </span>
            </v-tooltip>
          </div>
          <div
            v-if="publicProfile.ipu.wheel && publicProfile.ipu.wheel.length"
            class="ma-3"
          >
            <strong
              id="wheel-personality"
              class="text-center d-block"
            >
              {{ publicProfile.ipu.wheel[0].name }}
            </strong>
            <p>{{ getPersonalityText(publicProfile.ipu.wheel[0].name) }}</p>
          </div>
          <div
            v-else
            class="ma-3"
          >
            The result of the DISC assessment indicates that {{ publicProfile.firstName }} has an equal behavioural
            preference to the four categories (D, I, S and C). Hence it is not possible to locate
            {{ publicProfile.firstName }} in the wheel above. Please use the histogram view instead.
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!--
    <v-divider
      v-if="!publicProfile.ipu.hidden"
    />
    <div
      v-if="publicProfile.ipu && !publicProfile.ipu.hidden"
      class="flex-column behavioral-profile pt-3"
    >
      <h3
        class="white--text text-center pt-2 mb-4 pb-4"
        v-text="$t('Disc.driving_forces')"
      />
      <template v-if="publicProfile.ipu.ipuDrivingForces">
        <div
          v-for="(motivation, index) in publicProfile.ipu.ipuDrivingForces"
          :key="motivation.name"
          :index="index"
          class="driving-container mb-2"
        >
          <v-tooltip
            max-width="200"
            top
            color="light-blue darken-4"
          >
            <template #activator="{ on }">
              <v-chip
                :class="getColor(motivation.name)"
                class="justify-center"
                text-color="white"
                v-on="on"
              >
                {{ motivation.name }}
              </v-chip>
            </template>
            <span>{{ getMotivationsText(motivation.name) }}</span>
          </v-tooltip>
        </div>
      </template>
    </div>
    -->
  </div>
</template>
<script>
import DiscPie from '@/pages/profile/components/DiscPie.vue'
import { colors } from '@/config/drivingForceColors.js'

import { personalities } from '@/config/personalityShort.js'
import { importedPersonality } from '@/config/personality.js'
import { importedDrivingForces } from '@/config/drivingForces.js'
import { mapState } from 'vuex'

export default {
  name: 'PublicDisc',
  components: {
    DiscPie
  },
  props: {
    isMotivations: {
      type: Boolean,
      default: false
    },
    isPersonality: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ipuTabs: 0,
      drivingForces: importedDrivingForces,
      personalitiesTypes: importedPersonality,
      personalityShort: personalities,
      colors: colors
    }
  },
  computed: {
    ...mapState([
      'publicProfile'
    ]),
    ipuData () {
      return this.$store.state.publicProfile.ipu.disc[0]
    }
  },
  methods: {
    getPersonalityText (name) {
      for (const i in this.personalityShort) {
        if (name === i) {
          return this.personalityShort[i]
        }
      }
    },
    getMotivationsText (name) {
      for (const i in this.drivingForces) {
        if (name === this.drivingForces[i].name) {
          return this.drivingForces[i].description
        }
      }
    },
    getColor (name) {
      for (const i in this.colors) {
        if (name === i) {
          return this.colors[i]
        }
      }
    }
  }
}
</script>
