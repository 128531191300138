<template>
  <div>
    <h4
      class="resume-titles"
      v-text="$t('Pages.Profile.education')"
    />
    <v-row
      v-if="educations"
      class="resume-cards"
    >
      <v-col
        v-if="!educations.length"
        class="text-center py-4"
      >
        <EmptySectionText
          icon="school"
          :title="$t('Pages.Profile.no_education')"
        />
      </v-col>
      <v-col
        v-for="(education, index) in educations"
        v-else
        :id="'education-' + index"
        :key="'education-' + index"
        cols="12"
        sm="6"
      >
        <div
          is="ProfileCard"
          icon="school"
          :title="getEducationLevel(education.degreeLevel)"
          :fill-height="true"
          :subtitle="education.degree"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
export default {
  name: 'PublicProfileEducation',
  components: {
    EmptySectionText,
    ProfileCard
  },
  props: {
    educations: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    getEducationLevel (level) {
      const degrees = {
        no_formal: this.$t('Pages.Profile.no_formal'),
        course: this.$t('Pages.Profile.course'),
        certification: this.$t('Pages.Profile.certification'),
        higher_vocational: this.$t('Pages.Profile.higher_vocational'),
        primary: this.$t('Pages.Profile.primary'),
        secondary: this.$t('Pages.Profile.secondary'),
        bachelors: this.$t('Pages.Profile.bachelors'),
        masters: this.$t('Pages.Profile.masters'),
        licentiate: this.$t('Pages.Profile.licentiate'),
        doctorate_or_higher: this.$t('Pages.Profile.doctorate_or_higher')
      }
      for (const i in degrees) {
        if (level === i) {
          return degrees[i]
        }
      }
    }
  }
}
</script>
