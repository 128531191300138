<template>
  <v-container
    :class="{'fill-height': !publicProfileLoaded }"
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="publicProfileLoaded"
            id="app"
            class="profile-container profile-pages"
          >
            <div
              v-if="!missingAccess && publicProfile"
              id="profile"
              class="app-container edit-profile animate__animated animate__fadeIn mb-16"
            >
              <ProfileVisibilityAlert />
              <v-container fluid>
                <v-row
                  justify="center"
                >
                  <v-col
                    cols="12"
                    lg="10"
                    class="wides"
                  >
                    <v-btn
                      id="back-arrow"
                      class="ma-1"
                      rounded
                      text
                      outlined
                      @click="$router.go(-1)"
                    >
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-container fluid>
                      <!-- Back arrow aligned to left -->
                      <v-row
                        justify="center"
                      >
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-row>
                            <v-col cols="12">
                              <AccountInfoCardPublic
                                v-if="publicProfile && user.userId !== null"
                                :profile="publicProfile"
                                @connect-click="onGetInTouchClicked($route.params.id)"
                                @contract-click="onContractClicked($route.params.id)"
                              />
                            </v-col>
                            <v-col
                              v-if="publicProfile && publicProfile.ipu && publicProfile.ipu.disc"
                              cols="12"
                            >
                              <v-card
                                class="rounded-xl"
                                color="neutral"
                              >
                                <v-card-title
                                  primary-title
                                  class="justify-center"
                                >
                                  <h4>
                                    {{ $t('Pages.Profile.behavioral_assessment') }}
                                  </h4>
                                </v-card-title>
                                <v-divider />
                                <v-card-text
                                  class="pt-0 px-0"
                                >
                                  <p
                                    v-if="publicProfile && !publicProfile.ipu"
                                    class="mx-4 mt-2"
                                  >
                                    {{ publicProfile.firstName }} {{ publicProfile.lastName }}
                                    {{ $t('Pages.Search.not_taken') }}
                                  </p>
                                  <PublicDisc
                                    v-if="publicProfile && publicProfile.ipu"
                                    :is-motivations="isMotivations"
                                    :is-personality="isPersonality"
                                    :user="publicProfile"
                                  />
                                  <!--
                                  <router-link
                                    :to="{ name: 'disc' }"
                                    target="_blank"
                                    style="display: block; margin-top: 1rem;"
                                    class="text-center"
                                    rel="noreferrer"
                                    v-text="$t('Pages.Profile.read_more_analysis')"
                                  />
                                  -->
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <v-row>
                            <v-col
                              cols="12"
                            >
                              <PublicProfileDescription
                                :description="publicProfile.description"
                              />
                              <hr class="my-5">
                              <PublicProfileExperiences
                                :experiences="publicProfile.experiences"
                              />
                              <hr class="my-5">
                              <PublicProfileSkills
                                :skills="publicProfile.skills"
                                :first-name="publicProfile.firstName"
                              />
                              <hr class="my-5">
                              <PublicProfileLanguages
                                :languages="publicProfile.languages"
                              />
                              <hr class="my-5">
                              <PublicProfileEducation
                                :educations="publicProfile.educations"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div v-else>
              <ProfileUnavailable
                :missing-access="missingAccess"
              />
            </div>
            <v-dialog v-model="infoDrivingDialog">
              <CustomDialog
                title="Driving Forces & Behavioral style"
                :close-button="{
                  id: 'close-info-dialog',
                  text: 'Close'
                }"
                @close-event="infoDrivingDialog = false"
              >
                <v-card
                  slot="custom"
                  flat
                  color="neutral"
                  class="rounded-xl"
                >
                  <v-card-text>
                    <v-tabs
                      slot="extension"
                      v-model="infoTabs"
                      show-arrows
                      centered
                      background-color="neutral"
                      color="secondary"
                      grow
                    >
                      <v-tab>
                        Driving Forces
                      </v-tab>
                      <v-tab>
                        Behavior
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items
                      v-model="infoTabs"
                      class="neutral"
                    >
                      <v-tab-item>
                        <v-card
                          flat
                          color="neutral"
                          class="rounded-xl"
                        >
                          <v-card-text>
                            <p>
                              Driving forces are key internal forces that influence an individuals decisions. In
                              other words, your driving forces are what give you the power to make things happen.
                              Below is a list of the available driving forces accompanied with their definition.
                            </p>
                          </v-card-text>
                          <v-expansion-panels accordion>
                            <v-expansion-panel
                              v-for="force in drivingForces"
                              :key="force.id"
                            >
                              <v-expansion-panel-header color="neutral">
                                {{ force.name }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content color="neutral">
                                {{ force.description }}
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card
                          flat
                          color="neutral"
                          class="rounded-xl"
                        >
                          <v-card-text>
                            <p>
                              Behavioral research suggests that the most effective people are those who understand
                              themselves, both their strengths and weaknesses, so they can develop strategies to
                              meet the demands of their environment. A person's behavior is a natural and integral
                              part of who they are. Efficient teams consist of many different behavioral styles.
                            </p>
                          </v-card-text>
                          <v-expansion-panels accordion>
                            <v-expansion-panel
                              v-for="personalityType in personalitiesTypes"
                              :key="personalityType.id"
                            >
                              <v-expansion-panel-header color="neutral">
                                {{ personalityType.name }}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content color="neutral">
                                {{ personalityType.description }}
                                <div class="pieContainer search-pie">
                                  <div
                                    is="DiscPie"
                                    :personality="personalityType.name"
                                    :pie-size="'medium'"
                                  >
                                    <div
                                      slot="background"
                                      class="pieBackground search-pie-background"
                                    />
                                  </div>
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                </v-card>
              </CustomDialog>
            </v-dialog>
            <v-dialog v-model="contactDialog">
              <v-card
                color="neutral"
                class="rounded-xl"
              >
                <v-card-title
                  class="text-h6 font-weight-regular justify-space-between"
                  v-text="!isPremium ? $t('Pages.Profile.contact_title') : $t('Pages.Profile.contact_form')"
                />
                <v-card-text>
                  <v-form
                    v-if="!isPremium"
                    id="contact-form"
                    ref="contactFormRef"
                    v-model="validContactForm"
                    @submit.prevent="onSubmitContact()"
                  >
                    <v-text-field
                      id="company-name"
                      v-model="contactForm.companyName"
                      type="text"
                      color="accent"
                      :label="$t('Pages.Profile.company_name')"
                      :rules="[v => !!v || $t('Pages.Profile.required')]"
                      outlined
                      required
                    />
                    <v-text-field
                      id="contact-name"
                      v-model="contactForm.name"
                      type="text"
                      color="accent"
                      :label="$t('Pages.Profile.contact_person')"
                      :rules="[v => !!v || $t('Pages.Profile.required')]"
                      outlined
                      required
                    />
                    <v-text-field
                      id="contact-email"
                      v-model="contactForm.email"
                      type="text"
                      color="accent"
                      :label="$t('Pages.Profile.email')"
                      :rules="emailRules"
                      outlined
                      required
                    />
                    <v-text-field
                      id="contact-phone"
                      v-model="contactForm.phone"
                      type="tel"
                      color="accent"
                      outlined
                      :label="$t('Pages.Profile.phone')"
                      :rules="[v => !!v || $t('Pages.Profile.required')]"
                      required
                    />
                    <v-checkbox
                      id="contact-checkbox"
                      v-model="contactForm.terms"
                      color="accent"
                      :rules="[v => !!v || $t('Pages.Profile.terms_validation')]"
                    >
                      <template #label>
                        <div @click.stop>
                          {{ $t('Pages.Profile.terms_1') }}
                          <router-link
                            id="terms-link-1"
                            :to="{ name: 'tos-users' }"
                            target="_blank"
                            @click.stop
                            v-text="$t('Footer.terms')"
                          />
                          {{ $t('Pages.Profile.terms_2') }}
                          <router-link
                            :to="{ name: 'privacy-policy' }"
                            target="_blank"
                            @click.stop
                          >
                            <span v-text="$t('Footer.privacy')" />
                          </router-link>
                        </div>
                      </template>
                    </v-checkbox>
                    <div class="button-container">
                      <v-btn
                        text
                        rounded
                        @click="contactDialog = false"
                        v-text="$t('Pages.Search.close')"
                      />
                      <v-btn
                        id="submit-contact-form"
                        type="submit"
                        color="secondary"
                        class="neutral--text ml-2"
                        rounded
                        :disabled="!validContactForm || loadingContactForm"
                        :loading="loadingContactForm"
                      >
                        {{ $t('Pages.Profile.submit') }}
                      </v-btn>
                    </div>
                  </v-form>
                  <v-form
                    v-else
                    id="premium-contact-form"
                    ref="contactFormRef"
                    v-model="validPremiumContactForm"
                    @submit.prevent="onSubmitPremiumContact()"
                  >
                    <v-text-field
                      id="company-name"
                      v-model="premiumContactForm.companyName"
                      type="text"
                      color="accent"
                      :label="$t('Pages.Profile.company_name')"
                      :rules="[v => !!v || $t('Pages.Profile.required')]"
                      outlined
                      required
                    />
                    <v-text-field
                      id="contact-name"
                      v-model="premiumContactForm.contactPerson"
                      type="text"
                      color="accent"
                      :label="$t('Pages.Profile.contact_person')"
                      :rules="[v => !!v || $t('Pages.Profile.required')]"
                      outlined
                      required
                    />
                    <v-text-field
                      id="contact-email"
                      v-model="premiumContactForm.email"
                      type="text"
                      color="accent"
                      :label="$t('Pages.Profile.email')"
                      :rules="emailRules"
                      outlined
                      required
                    />
                    <v-text-field
                      id="contact-phone"
                      v-model="premiumContactForm.phone"
                      type="tel"
                      color="accent"
                      :label="$t('Pages.Profile.phone')"
                      :rules="[v => !!v || $t('Pages.Profile.required')]"
                      required
                      outlined
                    />
                    <v-textarea
                      id="contact-phone"
                      v-model="premiumContactForm.description"
                      rows="3"
                      color="accent"
                      :label="$t('Pages.Profile.contact_description')"
                      :rules="[v => !!v || $t('Pages.Profile.required')]"
                      required
                      outlined
                    />
                    <v-checkbox
                      id="contact-checkbox"
                      v-model="premiumContactForm.terms"
                      color="accent"
                      :rules="[v => !!v || $t('Pages.Profile.terms_validation')]"
                    >
                      <template #label>
                        <div @click.stop>
                          {{ $t('Pages.Profile.terms_1') }}
                          <router-link
                            id="terms-link"
                            :to="{ name: 'tos-users' }"
                            target="_blank"
                            @click.stop
                            v-text="$t('Footer.terms')"
                          />
                          {{ $t('Pages.Profile.terms_2') }}
                          <router-link
                            :to="{ name: 'privacy-policy' }"
                            target="_blank"
                            @click.stop
                          >
                            <span v-text="$t('Footer.privacy')" />
                          </router-link>
                        </div>
                      </template>
                    </v-checkbox>
                    <div class="button-container">
                      <v-btn
                        text
                        rounded
                        @click="contactDialog = false"
                        v-text="$t('Pages.Search.close')"
                      />
                      <v-btn
                        id="submit-contact-form-1"
                        type="submit"
                        color="secondary"
                        class="neutral--text ml-2"
                        rounded
                        :disabled="!validPremiumContactForm || loadingContactForm"
                        :loading="loadingContactForm"
                      >
                        {{ $t('Pages.Profile.submit') }}
                      </v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="contractDialog">
              <v-card
                color="neutral"
                class="rounded-xl"
              >
                <v-card-title
                  class="text-h6 font-weight-regular"
                >
                  <p class="mb-0">
                    {{ $t('Pages.Profile.send_contract_to') }} <span class="font-weight-bold">{{ `${publicProfile.firstName} ${publicProfile.lastName}` }}</span>
                  </p>
                </v-card-title>
                <v-card-text>
                  <v-form
                    id="contract-form"
                    ref="contractFormRef"
                    v-model="validContractForm"
                    @submit.prevent="onSubmitContractForm()"
                  >
                    <v-list
                      three-line
                      color="neutral"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>
                            mdi-upload
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('Pages.Profile.upload') }}</v-list-item-title>
                          <v-list-item-subtitle>{{ $t('Pages.Profile.upload_text') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>
                            mdi-pen
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('Pages.Profile.signing_parties') }}</v-list-item-title>
                          <v-list-item-subtitle>{{ $t('Pages.Profile.signing_parties_text') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>
                            mdi-email
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('Pages.Profile.send') }}</v-list-item-title>
                          <v-list-item-subtitle>{{ $t('Pages.Profile.send_text') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-file-input
                          multiple
                          :label="$t('Common.file_input')"
                          dense
                          show-size
                          outlined
                          required
                          @change="handleContractFileUpload($event)"
                        />
                      </v-list-item>
                    </v-list>
                    <div class="button-container">
                      <v-btn
                        text
                        rounded
                        @click="contractDialog = false"
                        v-text="$t('Pages.Search.close')"
                      />
                      <v-btn
                        id="submit-contact-form-1"
                        type="submit"
                        color="secondary"
                        class="neutral--text ml-2"
                        rounded
                        :disabled="!validContractForm || loadingContractForm || !contractFile"
                        :loading="loadingContractForm"
                      >
                        {{ $t('Pages.Profile.submit') }}
                      </v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-snackbar
              v-if="(!contactDialog || !premiumContactForm) && $route.params.id !== user.userId"
              v-model="contactSnackbar"
              color="neutral"
              rounded="pill"
              class="rounded-xl"
              :timeout="-1"
            >
              <v-btn
                text
                rounded
                color="secondary"
                @click="$router.go(-1)"
              >
                <v-icon left>
                  mdi-chevron-left
                </v-icon>
                {{ $t('Pages.Auth.back') }}
              </v-btn>
              <template #action="{ attrs }">
                <v-btn
                  v-if="!missingAccess && $route.params.id !== user.userId"
                  color="secondary"
                  class="neutral--text mr-2"
                  v-bind="attrs"
                  depressed
                  rounded
                  @click="onGetInTouchClicked($route.params.id)"
                  v-text="!isPremium ? $t('Pages.Profile.buy_subscription') : $t('Pages.Profile.contact_consultant')"
                />
              </template>
            </v-snackbar>
          </div>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
// import EditFavoriteGroupDialog from '@/common/EditFavoriteGroupDialog.vue'

import PublicDisc from '@/pages/profile/public/components/PublicDisc.vue'
import ProfileUnavailable from '@/pages/profile/public/components/PublicProfileUnavailable.vue'
import ProfileVisibilityAlert from '@/pages/profile/public/components/PublicProfileVisbilityAlert.vue'
import AccountInfoCardPublic from '@/pages/profile/public/components/PublicProfileAccountInfoCard.vue'

import PublicProfileDescription from '@/pages/profile/public/components/PublicProfileDescription.vue'
import PublicProfileExperiences from '@/pages/profile/public/components/PublicProfileExperiences.vue'
import PublicProfileLanguages from '@/pages/profile/public/components/PublicProfileLanguages.vue'
import PublicProfileEducation from '@/pages/profile/public/components/PublicProfileEducation.vue'
import PublicProfileSkills from '@/pages/profile/public/components/PublicProfileSkills.vue'

import DiscPie from '@/pages/profile/components/DiscPie.vue'
import CustomDialog from '@/common/CustomDialog.vue'
// import PageLoader from '@/common/PageLoader.vue'
import { formatDate } from '@/config/formatDate.js'
import { colors } from '@/config/drivingForceColors.js'
import { personalities } from '@/config/personalityShort.js'
import { importedPersonality } from '@/config/personality.js'
import { importedDrivingForces } from '@/config/drivingForces.js'
import { GET_PROFILE_BY_ID } from '@/store/actions.type'

export default {
  name: 'ProfilePublic',
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  components: {
    // EditFavoriteGroupDialog,
    AccountInfoCardPublic,
    CustomDialog,
    DiscPie,
    // PageLoader,
    ProfileUnavailable,
    ProfileVisibilityAlert,
    PublicDisc,
    PublicProfileDescription,
    PublicProfileExperiences,
    PublicProfileLanguages,
    PublicProfileSkills,
    PublicProfileEducation
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {
      }
    }
  },
  data () {
    return {
      contractStepper: 1,
      publicProfileLoaded: false,
      contactSnackbar: true,
      contactDialog: false,
      contractDialog: false,
      loadingContractForm: false,
      loadingContactForm: false,
      validContactForm: true,
      validContractForm: true,
      validPremiumContactForm: true,
      emailRules: [
        v => !!v || this.$tc('Validation.required'),
        v => /.+@.+/.test(v) || this.$tc('Validation.email')
      ],
      contactForm: {
        id: '',
        name: '',
        email: '',
        companyName: '',
        phone: '',
        terms: false
      },
      premiumContactForm: {
        id: '',
        contactPerson: '',
        email: '',
        companyName: '',
        phone: '',
        description: '',
        terms: false
      },
      chatBoxVisibility: false,
      drivingForces: importedDrivingForces,
      personalitiesTypes: importedPersonality,
      personalityShort: personalities,
      colors: colors,
      formatDate,
      missingAccess: true,
      valid: false,
      infoTabs: null,
      personality: '',
      isMotivations: false,
      isPersonality: false,
      infoDrivingDialog: false,
      favorites: [],
      contractFile: '',
      base64File: ''
    }
  },
  computed: {
    ...mapState([
      'brandName',
      'isPremium',
      'isAdmin',
      'publicProfile',
      'user'
    ])
  },
  mounted () {
    this.$store.dispatch(GET_PROFILE_BY_ID, this.$route.params.id).then(() => {
      this.publicProfileLoaded = true
      this.missingAccess = false
    }).catch(err => {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        if (
          err.response.status === 403 ||
          err.response.status === 500 ||
          err.response.status === 400
        ) {
          this.missingAccess = true
          this.publicProfileLoaded = true
        } else if (err.response.data.statusCode === 404) {
          this.publicProfileLoaded = true
          this.missingAccess = true
        } // Missing access, either invalid userId or hidden profile.
      } else if (err.request) {
        // client never received a response, or request never left
      } else {
        // anything else
        this.publicProfileLoaded = true
        this.missingAccess = true
      }
      this.$root.$emit('errorMessage', err)
    })
    if (
      this.publicProfile.ipu &&
      this.publicProfile.ipu.drivingForces &&
      this.publicProfile.ipu.drivingForces.length > 0
    ) {
      this.handlePersonality(this.publicProfile.ipu)
    }
  },
  beforeMount () {
    if (this.publicProfile) {
      this.handlePersonality(this.publicProfile.ipu)
      // if (this.isPremium) {
      //   this.getFavorites()
      // }
    }
  },
  methods: {
    handleContractFileUpload (files) {
      if (files) {
        this.contractFile = files[0]

        const reader = new FileReader()
        reader.onloadend = () => {
          this.base64File = reader.result
        }
        reader.readAsDataURL(files[0])
      }
    },
    onSubmitContractForm () {
      this.loadingContractForm = true
      const formData = new FormData()
      formData.append('file', this.contractFile)

      if (this.$refs.contractFormRef.validate()) {
        const contractPayload = {
          targetUserId: this.$route.params.id,
          subject: this.contractFile.name,
          data: this.base64File
        }
        this.$http.post('contracts', contractPayload).then(
          () => {
            setTimeout(() => {
              this.loadingContractForm = false
              this.contractDialog = false
              this.$root.$emit('successMessage', this.$t('Pages.Profile.contract_sent'))
            }, 1000)
          }, err => {
            console.error(err)
            setTimeout(() => {
              this.loadingContractForm = false
              this.$root.$emit('errorMessage', err.response)
            }, 1000)
          }
        )
      }
    },
    onSubmitContact () {
      this.loadingContactForm = true
      if (this.$refs.contactFormRef.validate()) {
        // Send info to email service
        this.$http.post(`profile/${this.contactForm.id}/interest`, this.contactForm).then(
          () => {
            setTimeout(() => {
              this.loadingContactForm = false
              this.contactDialog = false
              this.$root.$emit('successMessage', this.$t('Pages.Profile.contact_success'))
            }, 1000)
          }, err => {
            console.error(err)
            setTimeout(() => {
              this.loadingContactForm = false
              this.$root.$emit('errorMessage', err.response)
            }, 1000)
          }
        )
      }
    },
    onSubmitPremiumContact () {
      this.loadingContactForm = true
      if (this.$refs.contactFormRef.validate()) {
        // Send info to email service
        this.$http.post(`profile/${this.premiumContactForm.id}/contact`, this.premiumContactForm).then(
          () => {
            setTimeout(() => {
              this.loadingContactForm = false
              this.contactDialog = false
              this.$root.$emit('successMessage', this.$t('Pages.Profile.contact_success'))
            }, 1000)
          }, err => {
            console.error(err)
            setTimeout(() => {
              this.loadingContactForm = false
              this.$root.$emit('errorMessage', err.response)
            }, 1000)
          }
        )
      }
    },
    onGetInTouchClicked (userId) {
      if (this.isPremium) {
        this.premiumContactForm.id = userId
        this.contactDialog = true
      } else {
        this.$router.push({ name: 'pricing' })
      }
    },
    onContractClicked (userId) {
      this.contractDialog = true
    },
    handlePersonality (ipu) {
      if (ipu) {
        if (ipu.drivingForces) {
          this.isMotivations = ipu.drivingForces.length > 0
        }
        if (ipu.wheel) {
          this.isPersonality = ipu.wheel.length > 0
        }
      }
    },
    getFavorites () {
      this.$http.get('favorites').then(res => {
        this.favorites = res.data.groups
      }, err => {
        console.error(err)
      })
    }
  }
}
</script>

<style scoped>
#edit-profile-link:hover {
  color: #fff;
}
</style>
