var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"resume-titles mb-4",domProps:{"textContent":_vm._s(_vm.$t('Pages.Profile.skills_tools'))}}),(_vm.hasStar())?_c('p',[_vm._v(" "+_vm._s(_vm.$t('Pages.Profile.starred_1'))+" "+_vm._s(_vm.firstName)+" "+_vm._s(_vm.$t('Pages.Profile.starred_2'))+" ")]):_vm._e(),(_vm.skills)?_c('v-row',{staticClass:"resume-cards",attrs:{"id":"starred-skills"}},[(!_vm.skills.length)?_c('v-col',{staticClass:"text-center py-4"},[_c('EmptySectionText',{attrs:{"icon":"library_books","title":_vm.$t('Pages.Profile.no_skills_tools')}})],1):_vm._e(),(_vm.skills.length)?_vm._l((_vm.starSkills),function(skill,index){return _c('v-col',{key:'starred-' + index,attrs:{"id":'starred-' + index,"cols":"12","sm":"4"}},[_c("ProfileCard",{tag:"div",staticClass:"fab-30-px skill-card",attrs:{"title":skill.skill,"fill-height":true}},[(skill.star)?_c('v-btn',{staticClass:"avatar-chip elevation-1 fav-star",staticStyle:{"cursor":"default !important"},attrs:{"slot":"star","absolute":"","ripple":false,"fab":"","small":"","color":"success"},slot:"star"},[_c('v-icon',{staticClass:"star"},[_vm._v(" star ")])],1):_vm._e(),_c('div',{attrs:{"slot":"tooltip"},slot:"tooltip"},[_c('div',[(skill.monthsOfExperience !== null && skill.monthsOfExperience !== undefined)?_c('span',[_c('v-tooltip',{attrs:{"color":"light-blue darken-4","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',[_vm._v("schedule")]),(skill.monthsOfExperience >= 12)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate.formatYear(skill.monthsOfExperience))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate.formatRemainingMonths(skill.monthsOfExperience))+" ")],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Pages.Profile.skill_experience'))}})])],1):_vm._e()]),_c('div',[(skill.monthsSinceLastUsed !== null && skill.monthsSinceLastUsed !== undefined)?_c('v-tooltip',{attrs:{"color":"light-blue darken-4","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',[_vm._v("update")]),(skill.monthsSinceLastUsed >= 12)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate.formatYear(skill.monthsSinceLastUsed))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate.formatRemainingMonths(skill.monthsSinceLastUsed))+" ")],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Pages.Profile.last_used'))}})]):_vm._e()],1),(skill.monthsOfExperience === null || skill.monthsOfExperience === undefined)?_c('div',{staticStyle:{"height":"1.56rem"}}):_vm._e()])],1)],1)}):_vm._e()],2):_vm._e(),_c('v-expand-transition',[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.skillExpand || (_vm.starSkills.length + _vm.normalSkills.length) <= 5),expression:"skillExpand || (starSkills.length + normalSkills.length) <= 5"}],staticClass:"resume-cards px-0 py-4",attrs:{"id":"expanded-skills"}},[_c('v-row',{staticClass:"measuringWrapper"},_vm._l((_vm.normalSkills),function(skill,index){return _c('v-col',{key:'skill-' + index,attrs:{"id":'skill-' + index,"cols":"12","sm":"4"}},[_c("ProfileCard",{tag:"div",staticClass:"fab-30-px skill-card",attrs:{"title":skill.skill,"fill-height":true}},[(skill.star)?_c('v-btn',{staticClass:"avatar-chip elevation-1 fav-star fab-scaling-icon-hover",attrs:{"slot":"star","text":"","fab":"","small":"","color":"success"},slot:"star"},[_c('v-icon',{staticClass:"star"},[_vm._v(" star ")])],1):_vm._e(),_c('div',{attrs:{"slot":"tooltip"},slot:"tooltip"},[_c('div',[(skill.monthsOfExperience !== null && skill.monthsOfExperience !== undefined)?_c('span',[_c('v-tooltip',{attrs:{"color":"light-blue darken-4","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',[_vm._v("schedule")]),(skill.monthsOfExperience >= 12)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate.formatYear(skill.monthsOfExperience))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate.formatRemainingMonths(skill.monthsOfExperience))+" ")],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Pages.Profile.skill_experience'))}})])],1):_vm._e()]),_c('div',[(skill.monthsSinceLastUsed !== null && skill.monthsSinceLastUsed !== undefined)?_c('v-tooltip',{attrs:{"color":"light-blue darken-4","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',[_vm._v("update")]),(skill.monthsSinceLastUsed >= 12)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate.formatYear(skill.monthsSinceLastUsed))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate.formatRemainingMonths(skill.monthsSinceLastUsed))+" ")],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Pages.Profile.last_used'))}})]):_vm._e()],1),(skill.monthsOfExperience === null || skill.monthsOfExperience === undefined)?_c('div',{staticStyle:{"height":"1.56rem"}}):_vm._e()])],1)],1)}),1)],1)],1),(_vm.skills && _vm.skills.length && _vm.normalSkills && _vm.normalSkills.length)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[((_vm.starSkills.length + _vm.normalSkills.length) > 5)?_c('v-btn',{attrs:{"id":"show-more-btn","text":"","light":_vm.$route.name === 'profile',"rounded":""},on:{"click":function($event){_vm.skillExpand = !_vm.skillExpand}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.skillExpand),expression:"!skillExpand"}]},[_vm._v(" "+_vm._s(_vm.$t('Pages.Profile.show_more'))+"("+_vm._s(_vm.normalSkills.length)+") "),_c('v-icon',[_vm._v("keyboard_arrow_down")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.skillExpand),expression:"skillExpand"}]},[_vm._v(" "+_vm._s(_vm.$t('Pages.Profile.show_less'))),_c('v-icon',[_vm._v("keyboard_arrow_up")])],1)]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }