<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        class="mw-420"
      >
        <div class="top-card">
          <v-card
            color="neutral"
            class="error-cards rounded-xl"
          >
            <v-card-text
              class="text-center"
            >
              <h2>
                <v-icon x-large>
                  mdi-lock
                </v-icon>
              </h2>
              <h2
                class="my-3"
                v-text="$t('Pages.Profile.profile_not_available')"
              />
              <p v-text="$t('Pages.Profile.profile_hidden')" />
              <v-btn
                id="reset-back-btn"
                rounded
                text
                @click="$router.go(-1)"
              >
                {{ $t('Pages.Auth.back') }}
              </v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    missingAccess: {
      type: Boolean,
      default: false
    }
  }
}
</script>
