<template>
  <div>
    <h4
      class="resume-titles mb-4"
      v-text="$t('Pages.Profile.skills_tools')"
    />
    <p v-if="hasStar()">
      {{ $t('Pages.Profile.starred_1') }} {{ firstName }}
      {{ $t('Pages.Profile.starred_2') }}
    </p>
    <v-row
      v-if="skills"
      id="starred-skills"
      class="resume-cards"
    >
      <v-col
        v-if="!skills.length"
        class="text-center py-4"
      >
        <EmptySectionText
          icon="library_books"
          :title="$t('Pages.Profile.no_skills_tools')"
        />
      </v-col>
      <template v-if="skills.length">
        <v-col
          v-for="(skill, index) in starSkills"
          :id="'starred-' + index"
          :key="'starred-' + index"
          cols="12"
          sm="4"
        >
          <div
            is="ProfileCard"
            :title="skill.skill"
            :fill-height="true"
            class="fab-30-px skill-card"
          >
            <v-btn
              v-if="skill.star"
              slot="star"
              absolute
              :ripple="false"
              fab
              small
              style="cursor: default !important;"
              class="avatar-chip elevation-1 fav-star"
              color="success"
            >
              <v-icon class="star">
                star
              </v-icon>
            </v-btn>
            <div slot="tooltip">
              <div>
                <span
                  v-if="skill.monthsOfExperience !== null && skill.monthsOfExperience !== undefined"
                >
                  <v-tooltip
                    color="light-blue darken-4"
                    right
                  >
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon>schedule</v-icon>
                        <span v-if="skill.monthsOfExperience >= 12">
                          {{ formatDate.formatYear(skill.monthsOfExperience) }}
                        </span>
                        {{ formatDate.formatRemainingMonths(skill.monthsOfExperience) }}
                      </span>
                    </template>
                    <span v-text="$t('Pages.Profile.skill_experience')" />
                  </v-tooltip>
                </span>
              </div>
              <div>
                <v-tooltip
                  v-if="skill.monthsSinceLastUsed !== null && skill.monthsSinceLastUsed !== undefined"
                  color="light-blue darken-4"
                  right
                >
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-icon>update</v-icon>
                      <span v-if="skill.monthsSinceLastUsed >= 12">
                        {{ formatDate.formatYear(skill.monthsSinceLastUsed) }}
                      </span>
                      {{ formatDate.formatRemainingMonths(skill.monthsSinceLastUsed) }}
                    </span>
                  </template>
                  <span v-text="$t('Pages.Profile.last_used')" />
                </v-tooltip>
              </div>
              <div
                v-if="skill.monthsOfExperience === null || skill.monthsOfExperience === undefined"
                style="height: 1.56rem;"
              />
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
    <v-expand-transition>
      <v-container
        v-show="skillExpand || (starSkills.length + normalSkills.length) <= 5"
        id="expanded-skills"
        class="resume-cards px-0 py-4"
      >
        <v-row
          class="measuringWrapper"
        >
          <v-col
            v-for="(skill, index) in normalSkills"
            :id="'skill-' + index"
            :key="'skill-' + index"
            cols="12"
            sm="4"
          >
            <div
              is="ProfileCard"
              :title="skill.skill"
              :fill-height="true"
              class="fab-30-px skill-card"
            >
              <v-btn
                v-if="skill.star"
                slot="star"
                text
                fab
                small
                class="avatar-chip elevation-1 fav-star fab-scaling-icon-hover"
                color="success"
              >
                <v-icon class="star">
                  star
                </v-icon>
              </v-btn>
              <div slot="tooltip">
                <div>
                  <span
                    v-if="skill.monthsOfExperience !== null && skill.monthsOfExperience !== undefined"
                  >
                    <v-tooltip
                      color="light-blue darken-4"
                      right
                    >
                      <template #activator="{ on }">
                        <span v-on="on">
                          <v-icon>schedule</v-icon>
                          <span v-if="skill.monthsOfExperience >= 12">
                            {{ formatDate.formatYear(skill.monthsOfExperience) }}
                          </span>
                          {{ formatDate.formatRemainingMonths(skill.monthsOfExperience) }}
                        </span>
                      </template>
                      <span v-text="$t('Pages.Profile.skill_experience')" />
                    </v-tooltip>
                  </span>
                </div>
                <div>
                  <v-tooltip
                    v-if="skill.monthsSinceLastUsed !== null && skill.monthsSinceLastUsed !== undefined"
                    color="light-blue darken-4"
                    right
                  >
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon>update</v-icon>
                        <span v-if="skill.monthsSinceLastUsed >= 12">
                          {{ formatDate.formatYear(skill.monthsSinceLastUsed) }}
                        </span>
                        {{ formatDate.formatRemainingMonths(skill.monthsSinceLastUsed) }}
                      </span>
                    </template>
                    <span v-text="$t('Pages.Profile.last_used')" />
                  </v-tooltip>
                </div>
                <div
                  v-if="skill.monthsOfExperience === null || skill.monthsOfExperience === undefined"
                  style="height: 1.56rem;"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>
    <v-col
      v-if="skills && skills.length && normalSkills && normalSkills.length"
      cols="12"
      class="text-center"
    >
      <v-btn
        v-if="(starSkills.length + normalSkills.length) > 5"
        id="show-more-btn"
        text
        :light="$route.name === 'profile'"
        rounded
        @click="skillExpand = !skillExpand"
      >
        <span v-show="!skillExpand">
          {{ $t('Pages.Profile.show_more') }}({{ normalSkills.length }}) <v-icon>keyboard_arrow_down</v-icon>
        </span>
        <span v-show="skillExpand">
          {{ $t('Pages.Profile.show_less') }}<v-icon>keyboard_arrow_up</v-icon>
        </span>
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import { formatDate } from '@/config/formatDate.js'
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
export default {
  name: 'PublicProfileSkills',
  components: {
    EmptySectionText,
    ProfileCard
  },
  props: {
    skills: {
      type: Array,
      default: () => []
    },
    firstName: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      skillExpand: false,
      starSkills: [],
      normalSkills: [],
      formatDate
    }
  },
  watch: {
    skills: {
      immediate: true,
      handler (newSkills) {
        this.starSkills = []
        this.normalSkills = []

        if (newSkills && newSkills.length) {
          let starCount = 0
          for (let i = 0; i < newSkills.length; i++) {
            if (newSkills[i].star) {
              this.starSkills.push(newSkills[i])
              starCount++
            } else {
              this.normalSkills.push(newSkills[i])
            }
          }

          // If no skills are starred, add the first 5 skills to starSkills
          if (starCount === 0 && newSkills.length > 5) {
            this.starSkills = newSkills.slice(0, 5)
            this.normalSkills = newSkills.slice(5)
          }
        }
      }
    }
  },
  methods: {
    hasStar () {
      return this.starSkills.length > 0
    }
  }
}
</script>
