<template>
  <div>
    <h4 class="resume-titles">
      {{ $t('Pages.Profile.languages') }}
    </h4>
    <v-row
      v-if="languages"
      id="profile-languages"
      class="resume-cards"
    >
      <v-col
        v-if="!languages.length"
        class="text-center py-4"
      >
        <EmptySectionText
          icon="translate"
          :title="$t('Pages.Profile.no_languages')"
        />
      </v-col>
      <v-col
        v-for="(language, index) in languages"
        v-else
        :id="'language-' + index"
        :key="'language-' + index"
        cols="12"
        sm="4"
      >
        <div
          is="ProfileCard"
          :image-url="language.flagUrl"
          :title="language.language"
          :fill-height="true"
          :subtitle="getLanguageLevel(language.level)"
          class="language-card"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmptySectionText from '@/pages/profile/edit/components/EditProfileEmptySection.vue'
import ProfileCard from '@/pages/profile/components/ProfileCard.vue'
export default {
  name: 'PublicProfileLanguages',
  components: {
    EmptySectionText,
    ProfileCard
  },
  props: {
    languages: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    getLanguageLevel (level) {
      const levels = {
        1: this.$t('Common.elementary'),
        2: this.$t('Common.intermediate'),
        3: this.$t('Common.conversational'),
        4: this.$t('Common.fluent'),
        5: this.$t('Common.native')
      }
      for (const i in levels) {
        if (level === i) {
          return levels[i]
        }
      }
    }
  }
}
</script>
